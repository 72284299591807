import React from "react"
import { Link } from "gatsby"
import { cx } from "emotion"
import styled from "@emotion/styled"

const StyledLink = styled(Link)`
  &.unstyled {
    // Override unstyled link defaults
    color: ${props => props.theme.colors.white};

    &:visited,
    &:hover,
    &:active {
      color: ${props => props.theme.colors.white};
    }

    &:focus-visible {
      outline: 2px dotted ${props => props.theme.colors.white}
    }
  }
`

const NavLink = ({ className, children, to }) => {
  const classNames = cx(className, "unstyled")

  return (
    <StyledLink className={classNames} to={to}>
      {children}
    </StyledLink>
  )
}

export default NavLink
