import React from "react"
import { ThemeProvider as EmotionThemeProvider } from "emotion-theming"
import { colors, layout, spacers, typography } from "./themes"

// Inspired by:
// https://github.com/MaximeHeckel/react-hook-context-emotion-dark-mode
const defaultContextData = {
  dark: false,
  toggle: () => {},
}

const ThemeContext = React.createContext(defaultContextData)
const useTheme = () => React.useContext(ThemeContext)

const useEffectDarkMode = () => {
  const [themeState, setThemeState] = React.useState({
    dark: false,
    themeMounted: false,
  })

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const darkModeEnabled = localStorage.getItem("dark") === "true"

      setThemeState(prev => ({
        ...prev,
        dark: darkModeEnabled,
        themeMounted: true,
      }))
    }
  }, [])

  return [themeState, setThemeState]
}

const ThemeProvider = ({ children }) => {
  let [themeState, setThemeState] = useEffectDarkMode()

  const toggle = () => {
    const dark = !themeState.dark
    localStorage.setItem("dark", JSON.stringify(dark))

    setThemeState({ ...themeState, dark })
  }

  const theme = {
    colors,
    layout,
    spacers,
    typography,
    themeMounted: themeState.themeMounted,
    mode: themeState.dark ? "dark" : "light",
  }

  return (
    <EmotionThemeProvider theme={theme}>
      <ThemeContext.Provider
        value={{
          dark: themeState.dark,
          toggle,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </EmotionThemeProvider>
  )
}

export { ThemeProvider, useTheme }
