import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import TwitterLogo from "../images/twitter.inline.svg"
import GithubLogo from "../images/github.inline.svg"
import LinkedInLogo from "../images/linkedin.inline.svg"
import CodePenLogo from "../images/codepen.inline.svg"
import RssLogo from "../images/rss.inline.svg"

const logoComponents = {
  twitter: TwitterLogo,
  github: GithubLogo,
  linkedin: LinkedInLogo,
  codepen: CodePenLogo,
  rss: RssLogo,
}

const Link = styled.a`
  &.unstyled {
    display: block;
    color: ${props => props.theme.colors.white};

    &:hover,
    &:active {
      color: ${props => props.theme.colors.white};
    }
  }
`

const ChannelName = styled.span`
  display: block;
  margin-bottom: ${props => props.theme.spacers.base};
`

const renderChannelName = (channel, includeLabels) => {
  if (!includeLabels) return null

  return <ChannelName>{channel.name}</ChannelName>
}

const renderSocialLink = (channel, includeLabels) => {
  const Logo = logoComponents[channel.name.toLowerCase()]

  return (
    <>
      <Link className="unstyled logo-link" href={channel.url}>
        {renderChannelName(channel, includeLabels)}
        <Logo
          className="social-logo"
          aria-labelledby={`logo-title-${channel.name.toLowerCase()}`}
        />
      </Link>
    </>
  )
}

const SocialLinks = ({ includeLabels, includeRss }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            socialMedia {
              name
              url
            }
          }
        }
      }
    `
  )

  const { siteMetadata } = site

  let socialChannels = siteMetadata.socialMedia

  if (includeRss) {
    socialChannels = [
      ...socialChannels,
      {
        name: "RSS",
        url: `${process.env.GATSBY_SITE_URL}/feed.xml`,
      },
    ]
  }

  return (
    <ul className="social-links unstyled">
      {socialChannels.map((channel, index) => (
        <li className="unstyled social-link" key={index}>
          {renderSocialLink(channel, includeLabels)}
        </li>
      ))}
    </ul>
  )
}

SocialLinks.propTypes = {
  includeRss: PropTypes.bool,
  includeLabels: PropTypes.bool,
}

SocialLinks.defaultProps = {
  includeRss: false,
  includeLabels: false,
}

export default SocialLinks
