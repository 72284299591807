/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getSocialUrls = socialMedia => socialMedia.map(channel => channel.url)

const SEO = ({
  description,
  lang,
  meta,
  title,
  image,
  path,
  isArticle,
  publishDate,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            authorName
            image
            siteUrl: url
            socialMedia {
              url
            }
          }
        }
      }
    `
  )

  const { siteMetadata } = site
  const metaDescription = description || siteMetadata.description

  const metaImage = `${siteMetadata.siteUrl}${image || siteMetadata.image}`
  const metaType = isArticle ? "article" : "website"

  let metaUrl = []
  let canonicalLink = []
  let qualifiedUrl = ""

  if (path) {
    qualifiedUrl = `${siteMetadata.siteUrl}${path}`

    metaUrl = [...metaUrl, { name: `og:url`, content: qualifiedUrl }]
    canonicalLink = [...canonicalLink, { rel: `canonical`, href: qualifiedUrl }]
  }

  // Schema.org structured data
  const webPageSchema = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "@id": `${qualifiedUrl}#webpage`,
    url: qualifiedUrl,
    inLanguage: "en-US",
    description: metaDescription,
    name: siteMetadata.title,
    author: {
      "@type": "Person",
      name: siteMetadata.authorName,
    },
    isPartOf: {
      "@type": "WebSite",
      "@id": `${siteMetadata.siteUrl}/#website`,
      url: `${siteMetadata.siteUrl}/`,
      name: siteMetadata.title,
      publisher: {
        "@type": "Organization",
        "@id": `${siteMetadata.siteUrl}/#organization`,
        url: `${siteMetadata.siteUrl}/`,
        sameAs: getSocialUrls(siteMetadata.socialMedia),
      },
    },
  }

  const articleSchema = {
    "@context": "http://schema.org",
    "@type": "Article",
    "@id": `${qualifiedUrl}#article`,
    author: {
      "@type": "Person",
      name: siteMetadata.authorName,
    },
    datePublished: publishDate,
    description: metaDescription,
    headline: title,
    inLanguage: "en-US",
    url: qualifiedUrl,
    name: title,
    image: {
      "@type": "ImageObject",
      url: metaImage,
    },
    isPartOf: {
      "@type": "WebSite",
      "@id": `${siteMetadata.siteUrl}/#website`,
      url: `${siteMetadata.siteUrl}/`,
      name: siteMetadata.title,
    },
    publisher: {
      "@type": "Organization",
      "@id": `${siteMetadata.siteUrl}/#organization`,
      url: `${siteMetadata.siteUrl}/`,
      sameAs: getSocialUrls(siteMetadata.socialMedia),
    },
    mainEntityOfPage: `${qualifiedUrl}#webpage`,
  }

  const schema = isArticle ? articleSchema : webPageSchema

  const linkTags = [].concat(canonicalLink)

  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: metaType,
    },
    {
      property: `og:site_name`,
      content: `Sheelah Brennan`,
    },
    {
      property: `og:locale`,
      content: `en_US`,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      property: `twitter:image`,
      content: metaImage,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]
    .concat(meta)
    .concat(metaUrl)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={linkTags}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaTags}
      script={[
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(schema),
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  isArticle: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  image: PropTypes.string,
  title: PropTypes.string,
  isArticle: PropTypes.bool,
  path: PropTypes.string,
  publishDate: PropTypes.string,
}

export default SEO
