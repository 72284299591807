import React from "react"
import styled from "@emotion/styled"
import NavLink from "./nav-link"

const StyledNav = styled.nav`
  font-size: ${props => props.theme.typography.scale[1]};

  @media (min-width: 700px) {
    display: flex;
    justify-content: space-between;
    font-size: ${props => props.theme.typography.scale[3]};
  }

  .nav-link {
    padding-right: ${props => props.theme.spacers.sm};
    margin-top: ${props => props.theme.spacers.sm};

    @media (min-width: 700px) {
      padding: ${props =>
        `${props.theme.spacers.xs} ${props.theme.spacers.md}`};
      margin-top: 0;
    }
  }
`
const Nav = () => (
  <StyledNav>
    <NavLink className="nav-link" to="/blog">
      Blog
    </NavLink>
    <NavLink className="nav-link" to="/contact">
      Contact
    </NavLink>
  </StyledNav>
)

export default Nav
