import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useTheme } from "../context/ThemeContext"
import Container from "./container"
import SocialLinks from "./social-links"
import styled from "@emotion/styled"
import { withTheme } from "emotion-theming"
import plants from "../images/plants.svg"

const MainFooterContainer = styled(Container)`
  padding-top: ${props => props.theme.spacers.xl};
  padding-bottom: ${props => props.theme.spacers.xl};
  display: grid;
  grid-template-areas:
    "mode"
    "sitemap"
    "social";
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 1fr;
  gap: ${props => props.theme.spacers.lg};
  width: 100%;

  @media (min-width: 1000px) {
    align-items: start;
    justify-items: end;
    grid-template-areas: "social sitemap mode";
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    position: relative;
    z-index: 1; // On top of plant pseudoelement
  }
`

const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.offWhite};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: ${props => props.theme.typography.scale[3]};
    line-height: 1.8;
    margin: ${props => `0 0 ${props.theme.spacers.sm}`};
  }

  button[aria-checked="true"] span:last-of-type {
    color: ${props => props.theme.colors.white};
  }

  button[aria-checked="false"] span:last-of-type {
    color: ${props => props.theme.colors.purple};
  }

  button[aria-checked="true"] span:first-of-type {
    color: ${props => props.theme.colors.purple};
  }

  button[aria-checked="false"] span:first-of-type {
    color: ${props => props.theme.colors.white};
  }

  button[aria-checked="true"]:before {
    border-radius: 10px 0 0 10px;
  }

  button[aria-checked="false"]:before {
    transform: translateX(100%);
    border-radius: 0 10px 10px 0;
  }

  @media (min-width: 1000px) {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      background: url(${plants});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left center;
      width: 100%;
      height: 195%;
      position: absolute;
      top: 15px;
      right: 0;
      opacity: 0.2;
    }
  }
`

const Social = styled.div`
  color: ${props => props.theme.colors.offWhite};
  grid-area: social;

  .colophon {
    margin-bottom: 0;
  }

  .social-links {
    margin-bottom: ${props => props.theme.spacers.lg};
  }

  .social-link {
    display: inline-block;
    line-height: 0;
    margin-right: ${props => props.theme.spacers.base};

    &:last-of-type {
      margin-right: 0;
    }
  }

  .social-logo {
    fill: ${props => props.theme.colors.offWhite};
    transition: all 0.3s;
    width: 40px;

    &:hover,
    &:active {
      fill: ${props => props.theme.colors.white};
    }
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  color: inherit;

  &:focus {
    animation: jiggle 0.3s;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: ${props => props.theme.colors.white};
    transition: all 0.3s ease-in-out;
    transform: translateX(0);
  }

  span {
    line-height: 2;
    padding: 0 10px;
    color: ${props => props.theme.colors.purple};
    border: 1px solid ${props => props.theme.colors.white};
    font-weight: ${props => props.theme.typography.weights.bold};
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    border-radius: 10px 0 0 10px;
    position: relative;
    display: inline-block;
    width: 50%;

    &:nth-of-type(2) {
      border-radius: 0 10px 10px 0;
    }
  }

  @keyframes jiggle {
    0% {
      transform: rotate(-3deg);
    }
    50% {
      transform: rotate(6deg);
    }
  }
`

const StyledThemeOptions = styled.div`
  label {
    display: block;
    margin-bottom: ${props => props.theme.spacers.base};
  }

  grid-area: mode;
`

const StyledCopyright = styled.div`
  background-color: ${props => props.theme.colors.mediumPurple};
  font-size: ${props => props.theme.typography.scale[1]};
  padding: ${props => `${props.theme.spacers.sm} 0`};

  @media (min-width: 1000px) {
    text-align: center;
  }
`
const SiteMap = styled.div`
  grid-area: sitemap;

  // Use margin instead of padding to maintain link underline
  p {
    margin: ${props => `0 0 ${props.theme.spacers.sm}`};
  }

  @media (min-width: 1000px) {
    justify-self: center;
  }
`

const useThemeOptions = theme => {
  const themeState = useTheme()

  if (!theme.themeMounted) {
    return null
  }

  return (
    <StyledThemeOptions>
      <label id="dark-mode" htmlFor="toggle-button">
        <h3>Dark Mode</h3>
      </label>
      <StyledButton
        id="toggle-button"
        aria-labelledby="dark-mode"
        aria-checked={themeState.dark}
        role="switch"
        onClick={() => themeState.toggle()}
      >
        <span>On</span>
        <span>Off</span>
      </StyledButton>
    </StyledThemeOptions>
  )
}

const Footer = ({ theme }) => (
  <StyledFooter>
    <MainFooterContainer>
      <Social>
        <h3>Follow Me</h3>
        <SocialLinks includeRss />
        <p className="colophon">
          Built with &hearts; and <a href="https://www.gatsbyjs.org">Gatsby</a>,
          hosted on <a href="https://www.netlify.com/">Netlify</a>
        </p>
        <p className="promo">
          <a href="https://skl.sh/2UQcIn6">
            My new Skillshare course: Make Your Website Stand Out
          </a>
        </p>
      </Social>
      <SiteMap>
        <h3>Links</h3>
        <p>
          <Link to="/#about">About</Link>
        </p>
        <p>
          <Link to="/blog/">Blog</Link>
        </p>
        <p>
          <Link to="/contact">Contact</Link>
        </p>
      </SiteMap>
      {useThemeOptions(theme)}
    </MainFooterContainer>
    <StyledCopyright>
      <Container>
        <p className="copyright">
          © {new Date().getFullYear()} Sheelah Brennan. All Rights Reserved.
        </p>
      </Container>
    </StyledCopyright>
  </StyledFooter>
)

Footer.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Footer)
