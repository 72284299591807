import React from "react"
import styled from "@emotion/styled"
import Logo from "../images/sheelah-logo.inline.svg"
import Container from "./container"
import Nav from "./nav"
import NavLink from "./nav-link"

const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.purple};
  padding: ${props =>
    `${props.theme.spacers.base} ${props.theme.spacers.base} 12px`};

  @media (min-width: 700px) {
    padding: ${props => props.theme.spacers.base};
  }
`

const StyledLogo = styled(Logo)`
  fill: currentColor;
  margin-top: 6px;
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 700px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Header = () => (
  <StyledHeader>
    <StyledContainer>
      <NavLink to="/">
        <StyledLogo aria-labelledby="logo-title" />
      </NavLink>
      <Nav />
    </StyledContainer>
  </StyledHeader>
)

export default Header
