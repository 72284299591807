export const colors = {
  white: "#ffffff",
  offWhite: "#f1eef3",
  black: "#39393a",
  jetBlack: "#000000",
  lightGray: "#f1f1f1",
  lightishGray: "#a8a8a8",
  mediumGray: "#747077",
  gray: "#878488",
  blueGreen: "#3c8ea0",
  lightPurple: "#bfa6bf",
  purple: "#612e61",
  mediumPurple: "#521d52",
  darkestPurple: "#350e35",
  orange: "#d06066",
}

export const layout = {
  maxWidth: "1180px",
}

const baseSpace = 8

export const spacers = {
  xs: `${baseSpace / 2}px`,
  base: `${baseSpace}px`,
  sm: `${baseSpace * 2}px`,
  md: `${baseSpace * 3}px`,
  lg: `${baseSpace * 4}px`,
  xl: `${baseSpace * 8}px`,
}

export const typography = {
  scale: {
    "-1": "0.907rem",
    "0": "1rem", // 16px
    "1": "1.132rem",
    "2": "1.179rem",
    "3": "1.3rem",
    "4": "1.471rem",
    "5": "1.533rem",
    "6": "1.912rem",
    "7": "2.197rem",
  },
  weights: {
    normal: 400,
    bold: 700,
  },
}
