import React from "react"
import styled from "@emotion/styled"

const StyledContainer = styled.div`
  max-width: ${props => props.theme.layout.maxWidth};
  margin: 0 auto;
  padding-left: ${props => props.theme.spacers.sm};
  padding-right: ${props => props.theme.spacers.sm};

  @media (min-width: 700px) {
    padding-left: ${props => props.theme.spacers.lg};
    padding-right: ${props => props.theme.spacers.lg};
  }
`

const Container = ({ className, children }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
)

export default Container
