/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import emotionNormalize from "emotion-normalize"
import styled from "@emotion/styled"
import { ThemeProvider } from "../context/ThemeContext"
import Footer from "./footer"
import Header from "./header"

const globalStyles = css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /* Modular scale
    https://www.modularscale.com/?1,832,12&em&1.3
    https://www.codementor.io/ricardozea/100-responsive-typography-system-using-a-modular-scale-s5rhft58g
  */
  body {
    font-family: "PT Sans", Arial, sans-serif;
    font-size: calc(14px + 0.5vw); // Based on 1.3 modular scale
    line-height: calc(16px + 1.4vw);
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  figure {
    margin: 16px 0;
  }

  figcaption {
    font-style: italic;
    font-size: 1rem;
    text-align: center;
    line-height: 1.5;
    max-width: 80%;
    margin: 8px auto 32px;

    @media (min-width: 1000px) {
      max-width: 70%;
    }
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
    margin: 0;
  }

  // Focus styles
  // https://css-tricks.com/standardizing-focus-styles-with-css-custom-properties
  :is(a, button, input, textarea, summary):focus {
    outline: 2px dotted currentColor;
  }

  :is(a, button, input, textarea, summary):focus-visible {
    outline: 2px dotted currentColor;
  }

  // Remove default focus styles for browsers supporting :focus-visible
  :is(a, button, input, textarea, summary):focus:not(:focus-visible) {
    outline: none;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledLink = styled.a`
  font-size: ${props => props.theme.typography.scale[0]};
  position: absolute;
  left: -10000px;
  display: block;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:focus {
    position: relative;
    left: ${props => props.theme.spacers.sm};
    width: max-content;
    font-weight: ${props => props.theme.typography.weights.bold};
    height: auto;
    overflow: auto;
  }
`

const StyledMain = styled.main`
  flex: 1 0 auto;
  padding-bottom: ${props =>
    props.verticalPadding ? props.theme.spacers.lg : 0};

  @media (min-width: 1000px) {
    padding-bottom: ${props =>
      props.verticalPadding ? props.theme.spacers.xl : 0};
    padding-top: ${props =>
      props.verticalPadding ? props.theme.spacers.lg : 0};
  }
`

// TODO: figure out if there's a better way to inject theme data into global styles
const Layout = ({ children, verticalPadding }) => (
  <ThemeProvider>
    <Global
      styles={css`
        ${emotionNormalize};
        ${globalStyles};
      `}
    />
    <Global
      styles={theme =>
        css`
          body {
            background-color: ${theme.mode === "dark"
              ? theme.colors.darkestPurple
              : theme.colors.white};
            color: ${theme.mode === "dark"
              ? theme.colors.white
              : theme.colors.black};
          }

          // Base typography styles
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            margin: calc(12px + 1.8vw) 0 calc(12px + 1vw); // Responsive margins
            font-weight: ${theme.typography.weights.bold};
            line-height: 1.3;
          }

          h1 {
            font-size: ${theme.typography.scale[7]};
          }

          h2 {
            font-size: ${theme.typography.scale[6]};
          }

          h3 {
            font-size: ${theme.typography.scale[5]};
          }

          h4 {
            font-size: ${theme.typography.scale[4]};
          }

          h5 {
            font-size: ${theme.typography.scale[3]};
            line-height: calc(18px + 0.4vw);
          }

          h6 {
            font-size: ${theme.typography.scale[2]};
            line-height: calc(18px + 0.4vw);
          }

          // List styles
          li {
            margin-bottom: ${theme.spacers.md};
          }
          ul.unstyled,
          li.unstyled {
            margin: 0;
            padding: 0;
            line-height: 1;
          }

          li.unstyled {
            list-style-type: none;
          }

          // Base link styles
          a {
            color: currentColor;
            text-decoration: underline solid ${theme.colors.orange} 0.12em;
            text-underline-offset: 0.15em;
            transition: color 0.3s, text-decoration 0.3s, filter 0.3s;

            &:visited {
              color: currentColor;
            }

            &:hover,
            &:active {
              filter: brightness(150%);
            }
          }

          a.unstyled {
            text-decoration: none;

            &:hover,
            &:active {
              filter: none;
              color: ${theme.mode === "dark"
                ? theme.colors.lightPurple
                : theme.colors.purple};
            }
          }
        `
      }
    />
    <StyledDiv>
      <StyledLink href="#main-content">Skip to main content</StyledLink>
      <Header />
      <StyledMain id="main-content" verticalPadding={verticalPadding}>
        {children}
      </StyledMain>
      <Footer />
    </StyledDiv>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  verticalPadding: PropTypes.bool.isRequired,
}

Layout.defaultProps = {
  verticalPadding: true,
}

export default Layout
